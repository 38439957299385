.Game {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
