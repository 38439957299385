.Square {
  /* background: Red; */
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center
}

.Square .x, 
.Square .o {
    font-size: 2em;
    opacity: 0;
    position: absolute;
}

.Square.free {
    cursor: pointer;
}

.x .Square.free .x, 
.o .Square.free .o {
    opacity: 0.05;
    animation: show-turn-marker-animation 0.5s ease-in-out;

}

/* fade in the turn markers, but slighly slower */
.x.init .Square.free .x, 
.o.init .Square.free .o {
    animation: show-turn-marker-animation 1s ease-in;
}

@keyframes show-turn-marker-animation {
    from {opacity: 0}
    to {opacity: 0.05}
}

@media (hover: hover) and (pointer: fine) {
    .Square.free:hover {                
        /* background: rgb(240, 240, 240); */
        /* transition: opacity 0.2s ease-in-out; */
    }
}

@media (hover: hover) and (pointer: fine) {
    .Square.free.x:hover .x, 
    .Square.free.o:hover .o {                
        opacity: 0.2;
    }
}

.Square.free.x:active .x,
.Square.free.o:active .o {
    color: darkred;
    opacity: 0.5;
}

.Square.mark.x .x, 
.Square.mark.o .o {
    opacity: 1;
}

.Square.win {
    color: red;
    animation-name: win-animation;
    animation-duration: 0.2s;
    animation-iteration-count: 4;
    animation-direction: alternate;
    animation-timing-function: ease-out;
}

@keyframes win-animation {
    from {color: red;}
    to {color: black;}
}

.Menu .Square.win {
    /* don't buzz again when showing the overlayed menu */
    animation-name: none;
    /* a bit more subtle when under the menu */
    transition: color 0.6s ease-in;
    color: rgb(128, 0, 0);
}
