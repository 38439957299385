.Grid {
  width: 100%;
  height: 100%;
  border-spacing: 0; /* aka cellspacing */
}

.Row {
  height: 33%;
}

.Row:nth-child(2) td {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.Col {
  width: 33%;
  padding: 0; /* aka cellpadding */
}

.Col:nth-child(2) {
  border-left: 2px solid black;
  border-right: 2px solid black;
}
